<template>
  <div id="app">
    <slot />
  </div>
</template>

<style lang="scss">
#app {
  @apply flex flex-col;
  min-height: 100vh;
  width: 100%;

  & > div {
    @apply flex-grow;
  }
}
</style>
